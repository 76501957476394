import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TabCmp from "../components/tabcmp";


interface Props {}

const Home: React.FC<Props> = ({}) => {
  const navigate = useNavigate();

  useEffect(() => {
    let localToken = localStorage.getItem("userToken");
    console.log("[DEBUG] - vinh test: ", "localToken", localToken);
    if (localToken == null || localToken === "") {
      // TODO redirect to login
      navigate("/login");
    }
  }, []);

  return <h3 className="home-container">Welcome to Mobieyes Portal</h3>
};

export default Home;
