class LifeSmartHelper {
  static CURTAINS_DEVTYPES = [
    "SL_DOOYA",
    "SL_P_V2",
    "SL_CN_IF",
    "SL_CN_FE",
    "SL_SW_WIN",
  ];

  static HVAC_DEVTYPES = ["V_AIR_P"];

  static STATUS_DEVICES = ["SL_SC_BG"];

  static SWITCH_DEVTYPES = [
    // normal
    "SL_SW_CP3",
    "SL_SW_RC3",
    "SL_SF_RC",
    "SL_SW_IF3",
    "SL_SF_IF3",
    "SL_SW_RC",
    "SL_SW_IF2",
    "SL_SF_IF2",
    "SL_SW_CP2",
    "SL_SW_FE2",
    "SL_SW_RC2",
    "SL_SW_IF1",
    "SL_SF_IF1",
    "SL_SW_CP1",
    "SL_SW_FE1",
    "SL_OL_W",
    "SL_SW_RC1",

    // Stellar Switch Series
    "SL_SW_ND3",
    "SL_MC_ND3",
    "SL_SW_ND2",
    "SL_MC_ND2",
    "SL_SW_ND1",
    "SL_MC_ND1",

    // Nature Switch
    "SL_SW_NS3",
    "SL_SW_NS2",
    "SL_NATURE",

    // Switch Controller Series
    "SL_S",
    "SL_SPWM",
    "SL_P_SW",

    // General Controller Series
    "SL_P",
    "SL_JEMA",

    // CUBE Switch Module
    "SL_SW_MJ3",
    "SL_SW_MJ2",
    "SL_SW_MJ1",

    "SL_SW_NS1",
  ];

  static SWITCH_LIGHT_DEVTYPES = [
    // Light Series
    "OD_WE_QUAN",
    "SL_LI_UG1",
    "SL_LI_GD1",
    "SL_LI_WW",
    "SL_SC_RGB",
    "SL_LI_RGBW",
    "SL_CT_RGBW",

    // Spot light
    "MSL_IRCTL",
    "OD_WE_IRCTL",
    "SL_SPOT",
    "SL_P_IR",
  ];

  static ENV_SENSOR_DEVTYPES = ["SL_SC_BE", "SL_SC_THL"];

  static genCurtainJson = (item: any) => {
    let dataJson: any[] = [];
    let data: any = item.data;
    switch (item.devtype) {
      case "SL_DOOYA": {
        Object.keys(data).forEach((key: string) => {
          if (key === "P2") {
            dataJson.push({
              id: item.agt + "-*-" + item.me + "-*-" + key,
              board: item.me,
              name: item.name,
              type: "curtains",
              sourceType: "lifesmart",
              controlType: "curtain_type_1",
              actions: [
                {
                  name: "Close",
                  type: "PULSE",
                  icon: "cmd-close-active",
                  board: item.me,
                  ports: [key],
                },
                {
                  name: "Pause",
                  type: "PULSE",
                  icon: "cmd-pause-active",
                  board: item.me,
                  ports: [key],
                },
                {
                  name: "Open",
                  type: "PULSE",
                  icon: "cmd-open-active",
                  board: item.me,
                  ports: [key],
                },
              ],
            });
          }
        });
        break;
      }
      case "SL_P_V2": {
        dataJson.push({
          id: item.agt + "-*-" + item.me,
          board: item.me,
          name: item.name,
          type: "curtains",
          sourceType: "lifesmart",
          controlType: "curtain_type_2",
          actions: [
            {
              name: "Close",
              type: "PULSE",
              icon: "cmd-close-active",
              board: item.me,
              ports: ["P3"],
            },
            {
              name: "Pause",
              type: "PULSE",
              icon: "cmd-pause-active",
              board: item.me,
              ports: ["P4"],
            },
            {
              name: "Open",
              type: "PULSE",
              icon: "cmd-open-active",
              board: item.me,
              ports: ["P2"],
            },
          ],
        });
        break;
      }
      case "SL_CN_IF": {
        dataJson.push({
          id: item.agt + "-*-" + item.me,
          board: item.me,
          name: item.name,
          type: "curtains",
          sourceType: "lifesmart",
          controlType: "curtain_type_2",
          actions: [
            {
              name: "Close",
              type: "PULSE",
              icon: "cmd-close-active",
              board: item.me,
              ports: ["P3"],
            },
            {
              name: "Pause",
              type: "PULSE",
              icon: "cmd-pause-active",
              board: item.me,
              ports: ["P2"],
            },
            {
              name: "Open",
              type: "PULSE",
              icon: "cmd-open-active",
              board: item.me,
              ports: ["P1"],
            },
          ],
        });
        break;
      }
      case "SL_CN_FE": {
        dataJson.push({
          id: item.agt + "-*-" + item.me,
          board: item.me,
          name: item.name,
          type: "curtains",
          sourceType: "lifesmart",
          controlType: "curtain_type_2",
          actions: [
            {
              name: "Close",
              type: "PULSE",
              icon: "cmd-close-active",
              board: item.me,
              ports: ["P3"],
            },
            {
              name: "Pause",
              type: "PULSE",
              icon: "cmd-pause-active",
              board: item.me,
              ports: ["P2"],
            },
            {
              name: "Open",
              type: "PULSE",
              icon: "cmd-open-active",
              board: item.me,
              ports: ["P1"],
            },
          ],
        });
        break;
      }

      case "SL_SW_WIN": {
        dataJson.push({
          id: item.agt + "-*-" + item.me,
          board: item.me,
          name: item.name,
          type: "curtains",
          sourceType: "lifesmart",
          controlType: "curtain_type_3",
          actions: [
            {
              name: "Close",
              type: "PULSE",
              icon: "cmd-close-active",
              board: item.me,
              ports: ["CL"],
            },
            {
              name: "Pause",
              type: "PULSE",
              icon: "cmd-pause-active",
              board: item.me,
              ports: ["ST"],
            },
            {
              name: "Open",
              type: "PULSE",
              icon: "cmd-open-active",
              board: item.me,
              ports: ["OP"],
            },
          ],
        });
        break;
      }
      default:
        break;
    }

    return dataJson;
  };
  static genSwitchJson = (item: any, lightSeries: boolean) => {
    let dataJson: any[] = [];
    let data: any = item.data;
    Object.keys(data).forEach((key: string) => {
      if (lightSeries) {
        if (key === "RGBW" || key === "RGB" || key === "P1") {
          let dynPort = "";
          if (key === "RGBW") {
            dynPort = "DYN";
          }
          if (key === "RGB") {
            dynPort = "RGB";
          }
          if (key === "P1") {
            dynPort = "P2";
          }
          dataJson.push({
            id: item.agt + "-*-" + item.me,
            name: item.name,
            type: "switch_light",
            sourceType: "lifesmart",
            actions: ["ONOFF"],
            port: key,
            icon: "light",
            isOn: false,
            status: "0",
            board: item.me,
            remote: {
              light: {
                code: "",
                dynMode: false,
                dynPort: dynPort,
              },
            },
          });
        }
      } else {
        if (data[key].name !== undefined && data[key].name !== "") {
          // general switch
          if (item.devtype === "SL_P" || item.devtype === "SL_JEMA") {
            if (key === "P5" || key === "P6" || key === "P7") {
              dataJson.push({
                id: item.agt + "-*-" + item.me + "-*-" + key,
                name: data[key].name,
                type: "on_off_status",
                sourceType: "lifesmart",
                port: key,
                board: item.me,
                desc: "Trạng thái",
                showCommand: false,
                camPreset: "cua",
                actions: [],
                statusIcon: {
                  "0": {
                    image:
                      "https://cuongprodstatic.z11.web.core.windows.net/static/icons/actions/closer.png",
                  },
                  "1": {
                    image:
                      "https://cuongprodstatic.z11.web.core.windows.net/static/icons/actions/openg.png",
                  },
                },
              });
            } else {
              dataJson.push({
                id: item.agt + "-*-" + item.me + "-*-" + key,
                name: data[key].name,
                type: "switch",
                sourceType: "lifesmart",
                actions: ["ONOFF"],
                port: key,
                icon: "light",
                isOn: false,
                status: "0",
                board: item.me,
              });
            }
          } else {
            dataJson.push({
              id: item.agt + "-*-" + item.me + "-*-" + key,
              name: data[key].name,
              type: "switch",
              sourceType: "lifesmart",
              actions: ["ONOFF"],
              port: key,
              icon: "light",
              isOn: false,
              status: "0",
              board: item.me,
            });
          }
        }
      }
    });
    return dataJson;
  };

  static genACJson = (item: any) => {
    let dataJson: any[] = [];
    if (item.devtype === "V_AIR_P") {
      dataJson.push({
        id: item.agt + "-*-" + item.me,
        actions: ["ONOFF"],
        port: "",
        board: item.me,
        name: item.name,
        desc: item.name,
        type: "aircon",
        sourceType: "lifesmart",
        remote: {
          ac: {
            category: "vrv",
            brand: "vrv",
            config: {
              temp: {
                startIndex: 16,
                endIndex: 30,
                step: 1,
              },
              wind: {
                low: {
                  order: 1,
                  title: "Low",
                  key: "wind-low",
                  active: true,
                },
                medium: {
                  order: 2,
                  title: "Medium",
                  key: "wind-medium",
                  active: true,
                },
                high: {
                  order: 3,
                  title: "High",
                  key: "wind-high",
                  active: true,
                },
              },
              mode: {
                "air-auto": {
                  order: 1,
                  title: "Auto",
                  key: "mode-1",
                  active: true,
                },
                "air-fan": {
                  order: 2,
                  key: "mode-2",
                  active: true,
                },
                "air-cool": {
                  order: 3,
                  key: "mode-3",
                  active: true,
                },
                "air-heat": {
                  order: 4,
                  key: "mode-4",
                  active: true,
                },
                "air-humidity": {
                  order: 5,
                  key: "mode-5",
                  active: true,
                },
                "power-off": {
                  order: 6,
                  title: "off",
                  key: "power-off",
                  active: true,
                },
              },
            },
            controlValue: {
              key: "power",
              power: 0,
              mode: 1,
              temp: 25,
              windSpeed: 1,
            },
          },
        },
      });
    }
    return dataJson;
  };

  static genDeviceStatusJson = (item: any) => {
    let dataJson: any[] = [];
    let data: any = item.data;
    console.log("item.data: ", item.data);
    Object.keys(data).forEach((key: string) => {
      if (key === "G") {
        dataJson.push({
          id: item.agt + "-*-" + item.me + "-*-" + key,
          name: item.name,
          type: "doorlock",
          sourceType: "lifesmart",
          port: key,
          board: item.me,
          desc: "Trạng thái cửa",
          showCommand: false,
          camPreset: "cua",
          actions: [],
          statusIcon: {
            "0": {
              image:
                "https://cuongprodstatic.z11.web.core.windows.net/static/icons/actions/closer.png",
            },
            "1": {
              image:
                "https://cuongprodstatic.z11.web.core.windows.net/static/icons/actions/openg.png",
            },
          },
        });
      }
    });
    return dataJson;
  };

  static genEnvSensor = (item: any) => {
    let dataJson: any[] = [];
    dataJson.push({
      thermostat: {
        id: item.agt + "-*-" + item.me,
        name: item.name,
        sourceType: "lifesmart",
        port: ["T", "H"],
        value: {
          T: "0",
          H: "0",
        },
      },
    });
    return dataJson;
  };

  static genDeviceJson = (allDevice: any) => {
    let dataJson: any[] = [];
    allDevice.forEach((item: any) => {
      if (item.checked) {
        switch (true) {
          case this.ENV_SENSOR_DEVTYPES.includes(item.devtype): {
            let sensorJsons = LifeSmartHelper.genEnvSensor(item);
            if (sensorJsons.length > 0) {
              dataJson.push(...sensorJsons);
            }
            break;
          }
          case this.CURTAINS_DEVTYPES.includes(item.devtype): {
            let curtainJsons = LifeSmartHelper.genCurtainJson(item);
            if (curtainJsons.length > 0) {
              dataJson.push(...curtainJsons);
            }
            break;
          }
          case this.SWITCH_DEVTYPES.includes(item.devtype): {
            let switchJsons = LifeSmartHelper.genSwitchJson(item, false);
            if (switchJsons.length > 0) {
              dataJson.push(...switchJsons);
            }
            break;
          }
          case this.SWITCH_LIGHT_DEVTYPES.includes(item.devtype): {
            let switchJsons = LifeSmartHelper.genSwitchJson(item, true);
            if (switchJsons.length > 0) {
              dataJson.push(...switchJsons);
            }
            break;
          }
          case this.HVAC_DEVTYPES.includes(item.devtype): {
            let acJsons = LifeSmartHelper.genACJson(item);
            if (acJsons.length > 0) {
              dataJson.push(...acJsons);
            }
            break;
          }
          case this.STATUS_DEVICES.includes(item.devtype): {
            let statusJsons = LifeSmartHelper.genDeviceStatusJson(item);
            if (statusJsons.length > 0) {
              dataJson.push(...statusJsons);
            }
            break;
          }

          default:
            break;
        }
      }
    });
    return dataJson;
  };
  static genSpotDeviceJson = (allSpotDevice: any) => {
    let dataJson: any[] = [];
    allSpotDevice.forEach((item: any) => {
      if (item.checked && item.idx !== "") {
        switch (item.category) {
          case "tv": {
            dataJson.push({
              id: item.agt + "-*-" + item.me + "-*-" + item.idx,
              actions: [],
              board: item.me,
              port: item.idx,
              name: item.name,
              type: "TV",
              sourceType: "lifesmart",
              remote: {
                tv: {
                  category: item.category,
                  brand: item.brand,
                  controlKey: "",
                  config: {
                    power: "POWER",
                    chup: "CHANNELUP",
                    chdown: "CHANNELDOWN",
                    volup: "VOLUMEUP",
                    voldown: "VOLUMEDOWN",
                    "1": "1",
                    "2": "2",
                    "3": "3",
                    "4": "4",
                    "5": "5",
                    "6": "6",
                    "7": "7",
                    "8": "8",
                    "9": "9",
                    "0": "0",
                    "--/-": "--",
                    up: "UP",
                    down: "DOWN",
                    left: "LEFT",
                    right: "RIGHT",
                    ok: "OK",
                    menu: "MENU",
                    mute: "MUTE",
                    NICAM: "NICAM",
                    PARMUSIC: "PARMUSIC",
                    MODE: "MODE",
                    SLEEP: "SLEEP",
                    SWAP: "SWAP",
                    EXCHANGE: "EXCHANGE",
                    PIP: "PIP",
                    NORMAL: "NORMAL",
                    SELECT: "SELECT",
                    PICTURE: "PICTURE",
                    AUDIO: "AUDIO",
                    SCREEN: "SCREEN",
                    CYCLEWINDOWS: "CYCLEWINDOWS",
                  },
                },
                board: item.me,
                model: item.brand,
              },
            });
            break;
          }
          case "ac": {
            dataJson.push({
              id: item.agt + "-*-" + item.me + "-*-" + item.idx,
              actions: [],
              port: item.idx,
              board: item.me,
              name: item.name,
              desc: item.name,
              type: "aircon",
              sourceType: "lifesmart",
              remote: {
                ac: {
                  category: item.category,
                  brand: item.brand,
                  config: {
                    temp: {
                      startIndex: 16,
                      endIndex: 30,
                      step: 1,
                    },
                    wind: {
                      low: {
                        order: 1,
                        title: "Low",
                        key: "fan-1",
                        active: true,
                      },
                      medium: {
                        order: 2,
                        title: "Medium",
                        key: "fan-3",
                        active: true,
                      },
                      high: {
                        order: 3,
                        title: "High",
                        key: "fan-5",
                        active: true,
                      },
                    },
                    mode: {
                      "air-auto": {
                        order: 1,
                        title: "Auto",
                        key: "mode-0",
                        active: false,
                      },
                      "air-cool": {
                        order: 2,
                        key: "mode-1",
                        active: true,
                      },
                      "air-humidity": {
                        order: 3,
                        key: "mode-2",
                        active: true,
                      },
                      "air-filter": {
                        order: 4,
                        key: "mode-3",
                        active: false,
                      },
                      "air-heat": {
                        order: 5,
                        key: "mode-4",
                        active: true,
                      },
                      "power-on": {
                        order: 6,
                        key: "power-on",
                        active: true,
                      },
                      "power-off": {
                        order: 7,
                        key: "power-off",
                        active: true,
                      },
                    },
                  },
                  controlValue: {
                    key: "power",
                    power: 0,
                    mode: 1,
                    temp: 25,
                    windSpeed: 1,
                  },
                },
              },
            });
            break;
          }
          case "custom": {
            dataJson.push({
              id: item.agt + "-*-" + item.me + "-*-" + item.ai,
              actions: [],
              port: item.ai,
              board: item.me,
              name: item.name,
              desc: item.name,
              icon: "ico-remote",
              type: "ir_custom",
              sourceType: "lifesmart",
              remote: {
                custom: {
                  category: "custom",
                  brand: "custom",
                  configKey: {},
                  keySelected: "",
                },
              },
            });
            break;
          }
          default:
            break;
        }
      }
    });
    return dataJson;
  };
  static genSceneJson = (allScences: any) => {
    let dataJson: any[] = [];
    allScences.forEach((item: any) => {
      if (item.checked) {
        dataJson.push({
          name: item.name,
          // image: "cmd-power-off",
          sourceType: "lifesmart",
          actions: [
            {
              type: "PULSE",
              board: item.agt,
              value: item.id,
            },
          ],
        });
      }
    });
    return dataJson;
  };
}

export default LifeSmartHelper;
