import React from 'react';
import logo from './logo.png';
// import './App.css';
import Login from './views/login';
import routes from './routes'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Menu from './components/menu'
import Home from './views/home';
import Logout from './views/logout';
import LSDevice from './views/lifemart_device';
// import './setpProxy'


class App extends React.Component {
  render() {
    return (
        <Router>
            <div className="App">
                {/* Menu */}
                <Menu />
                {/* Noi Dung */}
                <Routes>
                  {/* { this.showContentMenu(routes) } */}
                    <Route 
                    path={"/"} 
                    // exact={route.exact} 
                    // component={route.main} 
                    element={<Home />}
                />
                <Route 
                    path={"/ls_device"} 
                    // exact={route.exact} 
                    // component={route.main} 
                    element={<LSDevice />}
                />
                <Route 
                    path={"/login"} 
                    // exact={route.exact} 
                    // component={route.main} 
                    element={<Login />}
                />
                <Route 
                    path={"/logout"} 
                    // exact={route.exact} 
                    // component={route.main} 
                    element={<Logout />}
                />
                </Routes>
            </div>
        </Router>
    );
}

showContentMenu = (routes: any) => {
  console.log('routes: ', routes)
    var result = null;

    if (routes.length > 0) {
        result = routes.map((route: {path: string, main: string}, index: any) => {
            return (
                <Route 
                    key={index} 
                    path={route.path} 
                    // exact={route.exact} 
                    // component={route.main} 
                    element={route.main}
                />
            );
        });
    }

    return result;
}
}

// function App() {
//   return (
//     <div className="App">
//       <body>
//       <img src={logo} className="app-logo" alt="logo" />
//       <Login />
//       </body>
//     </div>
//   );
// }

export default App;
