import React, { Component } from "react";
import "./menu.css";
import { useState, useEffect } from "react";

const menus = [
  {
    name: "Home",
    to: "/",
    exact: true,
  },
  {
    name: "LifeSmart Device",
    to: "/ls_device",
    exact: false,
  },
];

//Custom Link
// const MenuLink = ({
//     label,
//     to,
//     activeOnlyWhenExact
// }:{label: any, to: any, activeOnlyWhenExact: any}) => {
//     return (
//         <Route
//             path={to}
//             exact={activeOnlyWhenExact}
//             children={ ({match}:{match: any}) => { //match la doi tuong xac dinh su trung khop cua URL
//                 var active = match ? 'active abc' : '';

//                 return (
//                     <li classNameName={`my-li ${active}`}>
//                         <Link  to={to} classNameName="my-link">{label}</Link>
//                     </li>
//                 );
//             }}
//         />
//     );
// }

function Menu() {
  const [userName, setUsername] = useState("");

  useEffect(() => {
    let localToken = localStorage.getItem("userToken");
    console.log("localToken: ", localToken);
    if (localToken) {
        let userToken = JSON.parse(localToken!);
         setUsername(userToken.userName);
    }
  }, [userName]);

  return (
    <>
      <div className="sidebar-container">
        <div className="sidebar-logo">Mobieyes Portal</div>
        <div className="sidebar-username">user: {userName}</div>
        <ul className="sidebar-navigation">
          <li className="header">Navigation</li>
          <li>
            <a href="/">
              <i className="fa fa-home" aria-hidden="true"></i> Home
            </a>
          </li>
          <li>
            <a href="/ls_device">
              <i className="fa fa-tachometer" aria-hidden="true"></i> LifeSmart
              Device
            </a>
          </li>
          <li>
            <a href="/logout">
              <i className="fa fa-tachometer" aria-hidden="true"></i> Logout
            </a>
          </li>
          {/* <li>
                        <a href="/login">
                            <i className="fa fa-tachometer" aria-hidden="true"></i> Login
                        </a>
                    </li> */}
          {/* <li className="header">Another Menu</li>
                    <li>
                        <a href="#">
                            <i className="fa fa-users" aria-hidden="true"></i> Friends
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-cog" aria-hidden="true"></i> Settings
                        </a>
                    </li>
                    <li>
                        <a href="#">
                            <i className="fa fa-info-circle" aria-hidden="true"></i> Information
                        </a>
                    </li> */}
        </ul>
      </div>
    </>

    // <nav classNameName="navbar navbar-default">
    //     <ul classNameName="nav navbar-nav">
    //         <li>
    //             <NavLink to="/" classNameName="my-link">Trang Chu</NavLink>
    //         </li>
    //         <li>
    //             <NavLink  to="/ls_device" classNameName="my-link">LifeSmart Device</NavLink>
    //         </li>
    //         <li>
    //             <NavLink  to="/login" classNameName="my-link">Login</NavLink>
    //         </li>

    //         {/* <li>
    //             <NavLink exact activeclassNameName="active" to="/" classNameName="my-link">Trang Chu</NavLink>
    //         </li>
    //         <li>
    //             <NavLink activeclassNameName="active" to="/about" classNameName="my-link">Gioi Thieu</NavLink>
    //         </li>
    //         <li>
    //             <NavLink activeclassNameName="active" to="/contact" classNameName="my-link">Lien He</NavLink>
    //         </li> */}

    //         {/* <li classNameName="active">
    //             <NavLink exact to="/" classNameName="my-link">Trang Chu</NavLink>
    //         </li>
    //         <li classNameName="active">
    //             <NavLink to="/about" classNameName="my-link">Gioi Thieu</NavLink>
    //         </li>
    //         <li classNameName="active">
    //             <NavLink to="/contact" classNameName="my-link">Lien He</NavLink>
    //         </li>

    //         {/* Custom link */}
    //         {/* { this.showMenus(menus) } */}
    //     </ul>
    // </nav>
  );
}

// showMenus = (menus: any) => {
//     var result = null;

//     if (menus.length > 0) {
//         result = menus.map((menu: any, index: any) => {
//             return (
//                 <MenuLink
//                     key={index}
//                     label={menu.name}
//                     to={menu.to}
//                     activeOnlyWhenExact={menu.exact}
//                 />
//             );
//         });
//     }

//     return result;
// }

export default Menu;
