import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";

import lsService from "./../services/lifesmart";
import TabCmp from "../components/tabcmp";
import Popup from "../components/popup";
import LifeSmartHelper from "../helpers/lifesmart";

interface Props {}

const LSDevice: React.FC<Props> = ({}) => {
  const navigate = useNavigate();
  const [allDevice, setAllDevice] = useState<any>([]);
  const [allSpotDevice, setAllSpotDevice] = useState([]);
  const [allScences, setAllScences] = useState<any>([]);
  const [allChecked, setAllChecked] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [dataJson, setdataJson] = useState({});

  const closePopup = () => {
    setShowPopup(!showPopup);
  };

  const generateClick = (type: string) => {
    let dataJson: any[] = [];
    switch (type) {
      case "device": {
        if (allDevice.length > 0) {
          let jsonDevices = LifeSmartHelper.genDeviceJson(allDevice);
          if (jsonDevices.length > 0) {
            dataJson.push(...jsonDevices);
          }
        }
        break;
      }
      case "spot_device": {
        if (allSpotDevice.length > 0) {
          let jsonSpotDevices =
            LifeSmartHelper.genSpotDeviceJson(allSpotDevice);
          if (jsonSpotDevices.length > 0) {
            dataJson.push(...jsonSpotDevices);
          }
        }
        break;
      }
      case "scene": {
        if (allScences.length > 0) {
          let jsonScenes = LifeSmartHelper.genSceneJson(allScences);
          if (jsonScenes.length > 0) {
            dataJson.push(...jsonScenes);
          }
        }
        break;
      }
      default:
        break;
    }

    if (dataJson.length > 0) {
      console.log("json: ", dataJson);
      setShowPopup(true);
      setdataJson(dataJson);
    } else {
      alert("Let select device that you want generate please!");
    }
    setAllChecked(false);
    allDevice.forEach((item: any) => {
      item.checked = false;
    });
    allSpotDevice.forEach((item: any) => {
      item.checked = false;
    });
    allScences.forEach((item: any) => {
      item.checked = false;
    });
  };

  const checkboxOnchange = (event: any) => {
    console.log("checkboxOnchange", "event", event.id);

    if (event.id === "allchecked") {
      allDevice.forEach((item: any) => {
        item.checked = !allChecked;
      });
      allSpotDevice.forEach((item: any) => {
        item.checked = !allChecked;
      });
      allScences.forEach((item: any) => {
        item.checked = !allChecked;
      });
      setAllChecked(!allChecked);
    } else {
      switch (event.name) {
        case "device": {
          const device: any = allDevice.find(
            (item: any) => item.me === event.id
          );
          device.checked = !device.checked;
          console.log("checkboxOnchange", "device", device);
          setAllDevice([...allDevice]);
          break;
        }
        case "spot_device": {
          if (event.id !== "") {
            const device: any = allSpotDevice.find(
              (item: any) => item.ai === event.id
            );
            device.checked = !device.checked;
            console.log("checkboxOnchange", "device", device);
          }
          setAllSpotDevice([...allSpotDevice]);
          break;
        }
        case "scene": {
          const device: any = allScences.find(
            (item: any) => item.id === event.id
          );
          device.checked = !device.checked;
          console.log("checkboxOnchange", "device", device);
          setAllScences([...allScences]);
          break;
        }
        default:
          break;
      }
    }
  };

  useEffect(() => {
    let localToken = localStorage.getItem("userToken");
    if (localToken == null || localToken === "") {
      navigate("/login");
    }

    async function fetchAllDevice() {
      setAllChecked(false);
      let response = await lsService.getInstance().getAllDevice();
      if (response !== undefined) {
        setAllDevice(response);
      }

      let allDeviceLocal = allDevice;
      if (allDevice.length <= 0) {
        allDeviceLocal = response;
      }

      let spotDevice: any = allDeviceLocal.find(
        (item: any) =>
          item.devtype === "OD_WE_IRCTL" || item.devtype === "SL_SPOT" || item.devtype === "SL_P_IR"
      );
      if (spotDevice !== undefined && spotDevice.stat === 1) {
        let spotResponse = await lsService
          .getInstance()
          .getSpotRemoteList(spotDevice.agt);
        console.log("remote spot device: ", JSON.stringify(spotResponse));
        Object.keys(spotResponse).forEach((v: string) => {
          spotResponse[v].agt = spotDevice.agt;
          spotResponse[v].ai = v;
        })
        setAllSpotDevice(Object.values(spotResponse));
      }

      let allAgts: any[] = await lsService.getInstance().getAllSmartStation();
      if (allAgts !== undefined) {
        let scences: any[] = [];
        allAgts.forEach(async (v) => {
          let allScenes: any[] = await lsService
            .getInstance()
            .getScences(v.agt);
          console.log("Get sence: ", allScenes);
          if (allScenes.length > 0) {
            allScenes.forEach((item) => {
              item.agt = v.agt;
            });
            scences.push(...allScenes);
            console.log("Get scences1: ", scences);
            setAllScences(scences);
          }
        });
      }
    }
    fetchAllDevice();
  }, []);

  var deviceData = allDevice.map(function (obj: any, index: any) {
    return (
      <tr key={"device_" + obj.me + "_" + index}>
        <td>
          <input
            type="checkbox"
            id={obj.me}
            name="device"
            checked={obj.checked || false}
            onChange={(e) => {
              checkboxOnchange(e.target);
            }}
          />
        </td>
        <td>{index + 1}</td>
        <td>{obj.name}</td>
        <td>{obj.agt}</td>
        <td>{obj.me}</td>
        <td>{obj.devtype}</td>
        <td>{obj.stat === 1 ? "Active" : "InActive"}</td>
        <td className="raw-data">
          <pre>
            <textarea defaultValue={JSON.stringify(obj, null, 2)} />
          </pre>
        </td>
      </tr>
    );
  });

  var lsDevice = (
    <div className="ls_device-container" key="normal_device_tab">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Table striped bordered hover responsive="md">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    id="allchecked"
                    checked={allChecked}
                    onChange={(e) => {
                      checkboxOnchange(e.target);
                    }}
                  />
                </th>
                <th>No.</th>
                <th>Name</th>
                <th>Smart station Id</th>
                <th>Device Id</th>
                <th>Dev Type</th>
                <th>Status</th>
                <th>Data Raw</th>
              </tr>
            </thead>

            <tbody>{deviceData}</tbody>
          </Table>
          <button
            type="button"
            onClick={(e) => {
              generateClick("device");
            }}
          >
            Generate Json
          </button>
        </div>
      </div>
    </div>
  );

  var spotDeviceData = allSpotDevice.map(function (obj: any, index: any) {
    return (
      <tr key={"spot_device_" + obj.ai + "_" + index}>
        <td>
          <input
            type="checkbox"
            id={obj.ai}
            name="spot_device"
            checked={obj.checked || false}
            onChange={(e) => {
              checkboxOnchange(e.target);
            }}
          />
        </td>
        <td>{index + 1}</td>
        <td>{obj.name}</td>
        <td>{obj.ai}</td>
        <td>{obj.idx}</td>
        <td>{obj.brand}</td>
        <td>{obj.category}</td>
        <td>{obj.me}</td>
        <td className="raw-data">
          <pre>
            <textarea defaultValue={JSON.stringify(obj, null, 2)} />
          </pre>
        </td>
      </tr>
    );
  });

  var spotDevice = (
    <div className="ls_spot_device-container" key="spot_device_tab">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Table striped bordered hover responsive="md">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    id="allchecked"
                    checked={allChecked}
                    onChange={(e) => {
                      checkboxOnchange(e.target);
                    }}
                  />
                </th>
                <th>No.</th>
                <th>Name</th>
                <th>Ai</th>
                <th>Idx</th>
                <th>Brand</th>
                <th>Category</th>
                <th>Device Id</th>
                <th>Data Raw</th>
              </tr>
            </thead>

            <tbody>{spotDeviceData}</tbody>
          </Table>
          <button
            type="button"
            onClick={(e) => {
              generateClick("spot_device");
            }}
          >
            Generate Json
          </button>
        </div>
      </div>
    </div>
  );

  var scenesData = allScences.map(function (obj: any, index: any) {
    return (
      <tr key={"scene_" + obj.id + "_" + index}>
        <td>
          <input
            type="checkbox"
            id={obj.id}
            name="scene"
            checked={obj.checked || false}
            onChange={(e) => {
              checkboxOnchange(e.target);
            }}
          />
        </td>
        <td>{index + 1}</td>
        <td>{obj.id}</td>
        <td>{obj.name}</td>
        <td>{obj.desc}</td>
        <td className="raw-data">
          <pre>
            <textarea defaultValue={JSON.stringify(obj, null, 2)} />
          </pre>
        </td>
      </tr>
    );
  });

  var scenes = (
    <div className="ls_scene-container" key="scenes_tab">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <Table striped bordered hover responsive="md">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    id="allchecked"
                    checked={allChecked}
                    onChange={(e) => {
                      checkboxOnchange(e.target);
                    }}
                  />
                </th>
                <th>No.</th>
                <th>Id</th>
                <th>Name</th>
                <th>Desc</th>
                <th>Data Raw</th>
              </tr>
            </thead>

            <tbody>{scenesData}</tbody>
          </Table>
          <button
            type="button"
            onClick={(e) => {
              generateClick("scene");
            }}
          >
            Generate Json
          </button>
        </div>
      </div>
    </div>
  );

  const tabNames = Array<string>("All Device", "Spot Device", "Scene");

  const tabPanels = Array<any>(lsDevice, spotDevice, scenes);

  return (
    <div>
      {showPopup && (
        <Popup
          content={JSON.stringify(dataJson, null, 4)}
          handleClose={closePopup}
        />
      )}
      <TabCmp tabNames={tabNames} tabPanels={tabPanels} />
    </div>
  );
};
export default LSDevice;
