// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-container {
    margin-left: 500px;
}


.ls_scene-container .raw-data, 
.ls_spot_device-container .raw-data, 
.ls_device-container .raw-data{
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/views/style.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;;AAGA;;;IAGI,gBAAgB;AACpB","sourcesContent":[".home-container {\n    margin-left: 500px;\n}\n\n\n.ls_scene-container .raw-data, \n.ls_spot_device-container .raw-data, \n.ls_device-container .raw-data{\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
