import axios, { AxiosError } from "axios";
import lsConfig from "./config";
import md5 from "md5";
class LifeSmart {
  id?: number = Math.floor(100 + Math.random() * 900); //random 100->999
  userid?: string = "";
  usertoken?: string = "";

  auth_callback = "http://localhost:3000/login";

  async LSRequest(
    userId: string,
    userToken: string,
    method: any,
    params: any
  ) {
    let RequestObject = LsCmd(userId, userToken, method, params);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(RequestObject.data),
    };
    const response = await fetch(RequestObject.url, requestOptions);
    const data = await response.json();
    console.log("LS response:  ", JSON.stringify(data));
    // token invalid
    if (data.code === 10004) {
      console.log('Signature error')
      const lsUserInfo = localStorage.getItem("userToken");
      if (lsUserInfo != null && lsUserInfo !== "") {
        let userInfo = JSON.parse(lsUserInfo);
        try {
          const userTokenCached = await axios.post(
            lsConfig.MOBI_GATEWAY_HOST + "/Lifesmart/GetToken",
            {
              username: userInfo.userName,
              password: userInfo.passWord,
            }
          );
          console.log("userTokenCached: ", userTokenCached);
          if (userTokenCached.data !== undefined && userTokenCached.data.token !== userInfo.userToken) {
            localStorage.setItem("userToken", JSON.stringify({
              userId: userTokenCached.data.userId,
              userToken: userTokenCached.data.token,
              userName: userInfo.userName,
              passWord: userInfo.passWord
            }))
            return undefined;
          } else  {
            // trigger get new token
            await this.getUserToken(userInfo.userName, userInfo.passWord)
            return undefined;
          }
        } catch (error) {
          const err = error as AxiosError;
          console.log("get token error: ", err.message);
        }
      }
    }
  
    return data;
  }

  AuthGetRequest = () => {
    let utcTimeInSecond = Math.floor(Date.now() / 1000);
    let OriginalSign =
      "appkey=" +
      lsConfig.LS_APP_KEY +
      "&auth_callback=" +
      this.auth_callback +
      "&time=" +
      utcTimeInSecond +
      "&apptoken=" +
      lsConfig.LS_APP_TOKEN;

    let sign = md5(OriginalSign).toLowerCase();
    const requestCMD = "/app/auth.authorize?";
    return (
      requestCMD +
      "id=" +
      sessionId +
      "&appkey=" +
      lsConfig.LS_APP_KEY +
      "&time=" +
      utcTimeInSecond +
      "&auth_callback=" +
      this.auth_callback +
      "&sign=" +
      sign +
      "&lang=en"
    );
  };

  AuthLoginTokenRequest = (user: string, pwd: string) => {
    const requestCMD = "/app/auth.login?";
    let bodyFormData = {
      uid: user,
      pwd: pwd,
      appkey: "CLK98XgsizM6nsltEuAP9w",
    };
    return { url: requestCMD, data: bodyFormData };
  };

  AuthDoAuth = (userid: string, token: string) => {
    const requestCMD = "/app/auth.do_auth?";
    let bodyFormData = {
      userid: userid,
      appkey: lsConfig.LS_APP_KEY,
      token: token,
      rgn: "vn",
    };
    return { url: requestCMD, data: bodyFormData };
  };

  getRemoteListParams = (agt: any) => {
    return { agt: agt };
  };

  getAllDeviceParams = (degree: number) => {
    return {
      degree: degree,
    };
  };
  getScencesParams = (agt: any) => {
    return { agt: agt };
  };

  getAllDevice = async () => {
    console.log("*****[LIFESMART]***** - [START] - getAllDevice");
    let temp_userid = undefined;
    let temp_usertoken = undefined;
    const lsUserInfo = localStorage.getItem("userToken");
    if (lsUserInfo != null && lsUserInfo !== "") {
      let userInfo = JSON.parse(lsUserInfo);
      temp_userid = userInfo.userId;
      temp_usertoken = userInfo.userToken;
    } else {
      console.log("user token not exist in cache");
    }
    let res = await this.LSRequest(
      temp_userid,
      temp_usertoken,
      Method.getAllDevice,
      this.getAllDeviceParams(3)
    );
    console.log(
      "*****[LIFESMART]***** - [END] - getAllDevice: ",
      "res",
      JSON.stringify(res)
    );
    if (res !== undefined &&  res.status === "ok") {
      return res.message;
    }
    return undefined;
  };

  getAllSmartStation = async () => {
    console.log("*****[LIFESMART]***** - [START] - getAllSmartStation");
    let temp_userid = undefined;
    let temp_usertoken = undefined;
    const lsUserInfo = localStorage.getItem("userToken");
    if (lsUserInfo != null && lsUserInfo !== "") {
      let userInfo = JSON.parse(lsUserInfo);
      temp_userid = userInfo.userId;
      temp_usertoken = userInfo.userToken;
    } else {
      console.log("user token not exist in cache");
    }
    let res = await this.LSRequest(
      temp_userid,
      temp_usertoken,
      Method.getAllSmartStation,
      {}
    );
    console.log(
      "*****[LIFESMART]***** - [END] - getAllSmartStation: ",
      "res",
      JSON.stringify(res)
    );
    if (res !== undefined &&  res.status === "ok") {
      return res.message;
    }
    return undefined;
  };

  getScences = async (agt: string) => {
    let temp_userid = undefined;
    let temp_usertoken = undefined;
    const lsUserInfo = localStorage.getItem("userToken");
    if (lsUserInfo != null && lsUserInfo !== "") {
      let userInfo = JSON.parse(lsUserInfo);
      temp_userid = userInfo.userId;
      temp_usertoken = userInfo.userToken;
    } else {
      console.log("user token not exist in cache");
    }
    let res = await this.LSRequest(
      temp_userid,
      temp_usertoken,
      Method.getScene,
      this.getScencesParams(agt)
    );
    console.log(
      "*****[LIFESMART]***** - [END] - getAllDevice: ",
      "res",
      JSON.stringify(res)
    );
    if (res !== undefined &&  res.status === "ok") {
      return res.message;
    }
    return undefined;
  };

  // getSpotCategory = async () => {
  //   console.log('*****[LIFESMART]***** - getSpotCategory')
  //   let srv = lsService.getInstance()
  //   let lsCMD = await srv.cmd.spotGetCategory()
  //   let categories = await this.sendCmd(lsCMD)
  //   console.log(
  //     '*****[LIFESMART]*****All categories json: ' + JSON.stringify(categories)
  //   )
  // }

  // getSpotBrand = async (categoryName: string) => {
  //   console.log('*****[LIFESMART]***** - getSpotBrand')
  //   let srv = lsService.getInstance()
  //   let lsCMD = await srv.cmd.spotGetBrands(categoryName)
  //   let brands = await this.sendCmd(lsCMD)
  //   console.log(
  //     '*****[LIFESMART]*****All brands json: ' + JSON.stringify(brands)
  //   )
  // }

  // getSpotRemoteIdx = async (categoryName: string, brandName: string) => {
  //   console.log('*****[LIFESMART]***** - getSpotRemoteIdx')
  //   let srv = lsService.getInstance()
  //   let lsCMD = await srv.cmd.spotGetRemoteIdx(categoryName, brandName)
  //   let remoteIdxs = await this.sendCmd(lsCMD)
  //   console.log(
  //     '*****[LIFESMART]*****All remote idx json: ' + JSON.stringify(remoteIdxs)
  //   )
  // }

  getSpotRemoteList = async (agt: string) => {
    console.log("*****[LIFESMART]***** - getSpotRemoteList");
    let temp_userid = undefined;
    let temp_usertoken = undefined;
    const lsUserInfo = localStorage.getItem("userToken");
    if (lsUserInfo != null && lsUserInfo !== "") {
      let userInfo = JSON.parse(lsUserInfo);
      temp_userid = userInfo.userId;
      temp_usertoken = userInfo.userToken;
    } else {
      console.log("user token not exist in cache");
    }
    let res = await this.LSRequest(
      temp_userid,
      temp_usertoken,
      Method.getRemoteList,
      this.getRemoteListParams(agt)
    );
    console.log(
      "*****[LIFESMART]***** - [END] - getSpotRemoteList: ",
      "res",
      JSON.stringify(res)
    );
    if (res.status === "ok") {
      return res.message;
    }
    return undefined;

    // let srv = lsService.getInstance()
    // let lsCMD = await srv.cmd.getRemoteList(agt)
    // let remoteList = await this.sendCmd(lsCMD)
    // console.log(
    //   '*****[LIFESMART]*****All remote list json: ' + JSON.stringify(remoteList)
    // )
  };

  // buildDeviceId = (agt: string, me: string, idx: string) => {
  //   return agt.concat('_', me, '_', idx)
  // }

  logout = async () => {
    // await this.removeWebSocket()
    // await Cache.removeData('lsUserInfo')
  };

  getUserToken = async (username: string, password: string) => {
    const requestUserIdData = buildUserIdRequest(username, password);
    const resUserId = await axios.post(
      requestUserIdData.url,
      requestUserIdData.data
    );
    const resUserIdObj = resUserId.data;
    console.log('resUserIdObj :>> ', resUserIdObj);
    if (resUserIdObj.code === "success") {
      const TokenRequestData = buildUserTokenRequest(
        resUserIdObj.userid,
        resUserIdObj.token
      );
      const res = await axios.post(TokenRequestData.url, TokenRequestData.data);
      const resObj = res.data;
      console.log('resObj :>> ', resObj);

      // get token cached from server
      console.log("/Lifesmart/InsertToken");
      let userTokenUpdated = await axios.post(
        lsConfig.MOBI_GATEWAY_HOST + "/Lifesmart/InsertToken",
        {
          username: username,
          password: password,
          token: resObj!.usertoken,
          userId: resObj.userid,
        }
      );
      if (userTokenUpdated.data !== undefined) {
        console.log(
          "Update token cache server success",
          "userTokenUpdated",
          userTokenUpdated
        );
      }
      return {
        userId: resObj.userid,
        token: resObj.usertoken,
      };
    }
    return undefined;
  };

  login = async (user: string, pwd: string) => {
    console.log(
      "*****[LIFE_SMART]***** - LOGIN - START",
      "user: ",
      user,
      "pass: ",
      pwd
    );
    try {
      const userTokenCached = await axios.post(
        lsConfig.MOBI_GATEWAY_HOST + "/Lifesmart/GetToken",
        {
          username: user,
          password: pwd,
        }
      );
      // let userTokenCached = {
      //   data: {
      //     userId: "7791762",
      //     userToken: "AsnuZJLdAZ1APqoFu6T4YA",
      //   },
      // };
      // let userTokenCached = {
      //   data: undefined,
      // };
      console.log("userTokenCached: ", userTokenCached);
      if (userTokenCached.data !== undefined && userTokenCached.data?.userId) {
        console.log("return: ", userTokenCached.data);
        return userTokenCached.data;
      } else {
        return await this.getUserToken(user, pwd);
      }
    } catch (error) {
      console.log("get token error");
      const err = error as AxiosError;
      if (err.response) {
        return await this.getUserToken(user, pwd);
      }
    }
    console.log("*****[LIFE_SMART]***** - LOGIN - END");
    return undefined;
  };

  sendCmd = async (lsCommandObj: any) => {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(lsCommandObj.data),
      };
      const response = await fetch(lsCommandObj.url, requestOptions);
      let dataRes = response.body;
      // let response = await axios.post(lsCommandObj.url, lsCommandObj.data)
      // // const data = response.data;
      // let dataRes = response.data
      // console.log('sendCmd_res: ', JSON.stringify(dataRes))
      // if (dataRes.code !== 0) {
      //   return undefined
      // }
      return dataRes;
    } catch (error) {
      const err = error as AxiosError;
      if (err.response) {
        return undefined;
      }
    }
  };
}

function createMethod(method: string, url: string, desc: string) {
  return { method: method, url: url, description: desc };
}

const Method = Object.freeze({
  openWebsocket: createMethod(
    "WbAuth",
    lsConfig.STR_API,
    "Open Websocket Connection"
  ),
  removeWebsocket: createMethod(
    "RmAuth",
    lsConfig.STR_API,
    "remove websocket connection"
  ),
  getAllDevice: createMethod(
    "EpGetAll",
    lsConfig.STR_API,
    "Get All Devices of Account"
  ),
  getDeviceInfo: createMethod(
    "EpGet",
    lsConfig.STR_API,
    "Get specify device info"
  ),
  setDevice: createMethod("EpSet", lsConfig.STR_API, "control device"),
  getDeviceAttr: createMethod(
    "EpGetAttrs",
    lsConfig.STR_API,
    "get device details"
  ),
  getAllSmartStation: createMethod(
    "EpGetAllAgts",
    lsConfig.STR_API,
    "get all smart stations of account"
  ),
  getScene: createMethod(
    "SceneGet",
    lsConfig.STR_API,
    "get created scenes in account"
  ),
  setScene: createMethod("SceneSet", lsConfig.STR_API, "scene run"),
  setMultiDevice: createMethod(
    "EpsSet",
    lsConfig.STR_API,
    "control multidevices belong multi smartstation"
  ), //chua co ham tao params

  getCategory: createMethod("GetCategory", lsConfig.STR_IR, "spot api"),
  getBrands: createMethod("GetBrands", lsConfig.STR_IR, "spot api"),
  getRemoteIdxs: createMethod("GetRemoteIdxs", lsConfig.STR_IR, "spot api"),
  getRemoteList: createMethod("GetRemoteList", lsConfig.STR_IR, "spot api"),
  getCodes: createMethod("GetCodes", lsConfig.STR_IR, "spot api"),
  getACCodes: createMethod("GetACCodes", lsConfig.STR_IR, "spot api"),
  getCustomKeys: createMethod("GetCustomKeys", lsConfig.STR_IR, "spot"),
  sendIRKeys: createMethod("SendKeys", lsConfig.STR_IR, "spot"),
  sendACKeys: createMethod("SendACKeys", lsConfig.STR_IR, "spot api"),
});

function buildUserIdRequest(username: any, password: any) {
  let requestCMD = "/app/auth.login?";
  let bodyFormData = {
    uid: username,
    pwd: password,
    appkey: lsConfig.LS_APP_KEY,
  };
  return { url: requestCMD, data: bodyFormData };
}

function buildUserTokenRequest(userid: any, token: any) {
  let requestCMD = "/app/auth.do_auth?";
  let bodyFormData = {
    userid: userid,
    appkey: lsConfig.LS_APP_KEY,
    token: token,
    rgn: "vn",
  };
  return { url: requestCMD, data: bodyFormData };
}

function buildRequestId() {
  return Math.floor(100 + Math.random() * 900);
}

function LsCmd(
  userId: string,
  userToken: string,
  configMethod: any,
  params: any
) {
  let requestCMD = "/app/" + configMethod.url + configMethod.method;
  let method = configMethod.method;

  let utcTimeInSecond = Math.floor(Date.now() / 1000);
  var OriginalSign =
    "method:" +
    method +
    ",time:" +
    utcTimeInSecond +
    ",userid:" +
    userId +
    ",usertoken:" +
    userToken +
    ",appkey:" +
    lsConfig.LS_APP_KEY +
    ",apptoken:" +
    lsConfig.LS_APP_TOKEN;
  let Sign = md5(OriginalSign).toLowerCase();
  // let body = {}
  let bodyFormData = {
    id: sessionId,
    method: method,
    system: {
      ver: "1.0",
      lang: "en",
      userid: userId,
      time: utcTimeInSecond,
      appkey: lsConfig.LS_APP_KEY,
      sign: Sign,
    },
    params: {},
  };
  var paramString = "";
  console.log("params: ", JSON.stringify(params));
  for (var key in params) {
    if (params.hasOwnProperty(key)) {
      paramString = paramString + key + ":" + params[key] + ",";
    }
  }

  console.log("paramString: ", paramString);
  // console.log(paramString);
  OriginalSign =
    "method:" +
    method +
    "," +
    paramString +
    "time:" +
    utcTimeInSecond +
    ",userid:" +
    userId +
    ",usertoken:" +
    userToken +
    ",appkey:" +
    lsConfig.LS_APP_KEY +
    ",apptoken:" +
    lsConfig.LS_APP_TOKEN;
  console.log("LsCmd_", "OriginalSign: ", JSON.stringify(OriginalSign));
  Sign = md5(OriginalSign).toLowerCase();
  bodyFormData = {
    id: sessionId,
    method: method,
    system: {
      ver: "1.0",
      lang: "en",
      userid: userId,
      time: utcTimeInSecond,
      appkey: lsConfig.LS_APP_KEY,
      sign: Sign,
    },
    params: params,
  };

  console.log("LsCmd_", "bodyFormData: ", JSON.stringify(bodyFormData));

  return { url: requestCMD, data: bodyFormData };
}

async function LSRequest(
  userId: string,
  userToken: string,
  method: any,
  params: any
) {
  let RequestObject = LsCmd(userId, userToken, method, params);
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(RequestObject.data),
  };
  const response = await fetch(RequestObject.url, requestOptions);
  const data = await response.json();
  console.log("LS response:  ", JSON.stringify(data));
  // token invalid
  if (data.code === 10004) {
    console.log('Signature error')
    const lsUserInfo = localStorage.getItem("userToken");
    if (lsUserInfo != null && lsUserInfo !== "") {
      let userInfo = JSON.parse(lsUserInfo);
      try {
        const userTokenCached = await axios.post(
          lsConfig.MOBI_GATEWAY_HOST + "/Lifesmart/GetToken",
          {
            username: userInfo.userName,
            password: userInfo.passWord,
          }
        );
        console.log("userTokenCached: ", userTokenCached);
        if (userTokenCached.data !== undefined && userTokenCached.data.token !== userInfo.userToken) {
          localStorage.setItem("userToken", JSON.stringify({
            userId: userTokenCached.data.userId,
            userToken: userTokenCached.data.token,
            userName: userInfo.userName,
            passWord: userInfo.passWord
          }))
          return undefined;
        }
      } catch (error) {
        const err = error as AxiosError;
        console.log("get token error: ", err.message);
      }
    }
  }

  return data;
}

var sessionId = buildRequestId();

const lsController = (function () {
  let instance: LifeSmart;
  function init() {
    sessionId = buildRequestId();
    return new LifeSmart();
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
export default lsController;
