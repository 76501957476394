// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
}

.box {
position: relative;
width: 70%;
margin: 0 auto;
height: auto;
max-height: 70vh;
margin-top: calc(100vh - 85vh - 20px);
background: #fff;
border-radius: 4px;
padding: 20px;
border: 1px solid #999;
overflow: auto;
}

.close-icon {
content: 'x';
cursor: pointer;
position: fixed;
right: calc(15% - 30px);
top: calc(100vh - 85vh - 33px);
background: #ededed;
width: 25px;
height: 25px;
border-radius: 50%;
line-height: 20px;
text-align: center;
border: 1px solid #999;
font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,qBAAqB;IACrB,WAAW;IACX,aAAa;IACb,MAAM;IACN,OAAO;AACX;;AAEA;AACA,kBAAkB;AAClB,UAAU;AACV,cAAc;AACd,YAAY;AACZ,gBAAgB;AAChB,qCAAqC;AACrC,gBAAgB;AAChB,kBAAkB;AAClB,aAAa;AACb,sBAAsB;AACtB,cAAc;AACd;;AAEA;AACA,YAAY;AACZ,eAAe;AACf,eAAe;AACf,uBAAuB;AACvB,8BAA8B;AAC9B,mBAAmB;AACnB,WAAW;AACX,YAAY;AACZ,kBAAkB;AAClB,iBAAiB;AACjB,kBAAkB;AAClB,sBAAsB;AACtB,eAAe;AACf","sourcesContent":[".popup-box {\n    position: fixed;\n    background: #00000050;\n    width: 100%;\n    height: 100vh;\n    top: 0;\n    left: 0;\n}\n\n.box {\nposition: relative;\nwidth: 70%;\nmargin: 0 auto;\nheight: auto;\nmax-height: 70vh;\nmargin-top: calc(100vh - 85vh - 20px);\nbackground: #fff;\nborder-radius: 4px;\npadding: 20px;\nborder: 1px solid #999;\noverflow: auto;\n}\n\n.close-icon {\ncontent: 'x';\ncursor: pointer;\nposition: fixed;\nright: calc(15% - 30px);\ntop: calc(100vh - 85vh - 33px);\nbackground: #ededed;\nwidth: 25px;\nheight: 25px;\nborder-radius: 50%;\nline-height: 20px;\ntext-align: center;\nborder: 1px solid #999;\nfont-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
