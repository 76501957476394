import "./popup.css";

function Popup(props: any) {
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}>
          x
        </span>
        <pre>{props.content}</pre>
      </div>
    </div>
  );
}

export default Popup;
