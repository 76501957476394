export default class LifeSmartConfig {
  static LS_URL: string = 'https://api.apz.ilifesmart.com'
  static LS_WEB_SOCKET_URL: string = 'wss://api.apz.ilifesmart.com:8443/wsapp/'
  static LS_APP_KEY: string = 'CLK98XgsizM6nsltEuAP9w'
  static LS_APP_TOKEN: string = 'fXL6APoQof80z3JvbqCXbA'
  static MOBI_GATEWAY_HOST: string = 'https://api.mobieyes.vn//MobiEyesGateway/api'

  static STR_API = 'api.'
  static STR_IR = 'irapi.'
}
