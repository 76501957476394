import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Props {}

const Logout: React.FC<Props> = ({}) => {
  const navigate = useNavigate();

  useEffect(() => {
    let localToken = localStorage.getItem("userToken");
    console.log("[DEBUG] - vinh test: ", "localToken", localToken);
    if (localToken != null && localToken !== "") {
      // eslint-disable-next-line no-restricted-globals
      let ok = confirm("Are you sure to logout?");
      if (ok) {
        localStorage.setItem("userToken", "");
      }
    }
    navigate("/login");
    return;
  }, []);

  return null;
};

export default Logout;
