import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./tabcmp.css";
function TabCmp(props: any) {
  var tabNamesRender = props.tabNames.map(function (
    value: string,
  ) {
    return <Tab key={value}>{value}</Tab>;
  });
  var tabPanelsRender = props.tabPanels.map(function (
    value: any,
    index: number
  ) {
    return (
      <TabPanel key={index}>
        {value}
      </TabPanel>
    );
  });
  return (
    <div className="tabcmp-container">
      <Tabs className="Tabs">
        <TabList>{tabNamesRender}</TabList>
        {tabPanelsRender}
      </Tabs>
    </div>
  );
}

export default TabCmp;
